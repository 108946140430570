<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
.menu-bar
  height: 40px
.frame
  width: calc(100vw - 300px)
  height: calc(100vh - 40px)
  overflow: scroll
.table-project
  width: 3000px
  border-color: #d4d4d4
  font-size: 14px
  border-width: 1px 0px 1px 0px
  th, .th
    padding: 0.5rem
    &.header
      color: #999
      font-weight: normal
      &:hover
        background-color: #f0f0f0
        cursor: pointer
  .th-check
    width: 40px
    padding-top: 7px
    padding-left: 5px
    text-align: center
  .td-check
    width: 40px
    padding: 7px 0px 7px 6px
    text-align: center
  tbody
    tr
      td
        padding: 0.5rem

        &.hover
          // cursor: pointer
          .hover-visible
            visibility: hidden
          &:hover
            background-color: #fafafa
            // background: rgb(2,0,36);
            background: linear-gradient(180deg, #fefefe 0%, #fafafa 100%)
            box-shadow: 0px 2px 5px -2px rgba(0,0,0,0.5)
            .hover-visible
              visibility: visible

        &.row-selected
          background-color: mix(#007bff, #f0f0f0, 8%)

          td
            border-top-color: mix(#007bff, #f0f0f0, 20%)

      .td
        overflow: hidden
        span
          width: inherit
          // display: block
          overflow: hidden
          word-break: break-word
          // white-space: nowrap
.col-100
  max-width: 100px
  display: inline-flex
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
.col-150
  max-width: 150px
  display: inline-flex
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
.card-project-record
  background-color: #fff
  font-size: 14px
  border-color: #fff !important
  border-bottom-color: #d4d4d4 !important
  &:hover
    // background-color: rgba(darken(#f8f9fa, 50%), .03)
    border: solid 1px #777 !important
    cursor: pointer
.th-group
  // text-align: center
  // background-color: mix(#f1c40f, #f8f9fa, 20%)
  font-weight: 600 !important
  // background-color: #f8f9fa
  // z-index: 10
  // left: 0px
.td-group
  // text-align: center
  color: #555
  // color: #999
  font-weight: 600 !important
  // background-color: #f8f9fa
  // background-color: rgba(darken(#f8f9fa, 50%), .03)
  // z-index: 10
  // left: 0px
  // color: mix(#f1c40f, #555, 50%)
.note
  font-size: 12px
  white-space: pre-line
  word-break: break-word
  font-weight: 600
.today
  color: tomato
  float: right
  font-size: 11px
  // line-height: 20px
  font-weight: bold
.day-sun
  .dow
    color: tomato !important
.day-sat
  .dow
    color: royalblue !important
.ghost-hide
  opacity: 0 !important
.bg-lane
  background-color: darken(#e9ecef, 0%)
.text
  word-break: break-word
  white-space: pre-wrap
</style>
<template lang="pug">
div.async(:class='{done: done}' @click='$root.$emit("bv::hide::popover")')
  .menu-bar.d-flex
    .p-2
      button.btn.btn-default#opt_pipeline(@click.stop='' type='button') {{pipeline.name}}
      b-popover(ref='opt_pipeline' target='opt_pipeline' triggers='click blur' no-fade placement='bottomright' custom-class='popover-dropdown' :offset='"70px"')
        div(v-if='pipeline && pipeline.config')
          span.dropdown-label.px-2 파이프라인 이름
          .p-2
            input.form-control.form-control-sm(type='text' v-model.lazy='pipeline.name' @blur='save_pipeline_name')
          span.dropdown-label.px-2 고객 표시
          button.btn.btn-default.btn-block.text-left.rounded-0.link.m-0(
            type='button' @click.stop='toggle_customer("name")') 이름
              span.float-right(v-show='!pipeline.config.show_customer_name') 표시안함
              span.float-right.text-primary(v-show='pipeline.config.show_customer_name') 표시
          button.btn.btn-default.btn-block.text-left.rounded-0.link.m-0(
            type='button' @click.stop='toggle_customer("phone")') 전화번호
              span.float-right(v-show='!pipeline.config.show_customer_phone') 표시안함
              span.float-right.text-primary(v-show='pipeline.config.show_customer_phone') 표시
          button.btn.btn-default.btn-block.text-left.rounded-0.link.m-0(
            type='button' @click.stop='toggle_customer("email")') 이메일
              span.float-right(v-show='!pipeline.config.show_customer_email') 표시안함
              span.float-right.text-primary(v-show='pipeline.config.show_customer_email') 표시
        .border-top.opacity-50
        button.btn.btn-default.btn-block.rounded-0.link.text-left.text-danger(type='button' @click='delete_pipeline') 삭제
      b-spinner.ml-2.async(label='가져오는 중...' small variant='secondary' :class='{done:loading}')

  .frame
    //- pre {{pipeline}}
    div.pt-3.pl-3
      div.d-flex.table-project.border-bottom.position-sticky.bg-white(style='width: max-content; top:0px; z-index: 10; margin-bottom: -1px;')
        draggable(v-if='pipeline && pipeline.config' :class='{dragging: status_drag}' v-model='pipeline.config.status' handle='.handle' v-bind='status_drag_options' @start='status_drag = true' @end='status_drag_end')
          transition-group.d-flex(type='transition' :name="!status_drag ? 'flip-list' : null")
            div.header.handle.th(:key='state.id' style='width: 240px' v-for='(state, i) in pipeline.config.status'
              :id='`opt_stage_${state.id}`'
              tabindex='a'
            )
              //- @click='$root.$emit("bv::show::popover", `opt_field_table_${col.key}`)'
              //- @click='rename_stage(state)'
              strong.text-dark {{state.name}}
              //- strong.float-right.text-dark {{ count_records_by_status(state.id) }}
              strong.ml-2(v-if='pipeline.config.stat && pipeline.config.stat.count_status && pipeline.config.stat.count_status[i]') {{ pipeline.config.stat.count_status[i].count }}
              b-popover(:ref='`opt_stage_${state.id}`' :target='`opt_stage_${state.id}`' triggers='click blur' no-fade placement='right' custom-class='popover-dropdown')
                span.dropdown-label.px-2 단계 이름
                .p-2
                  input.form-control.form-control-sm(type='text' v-model.lazy='state.name' @blur='save_pipeline_status' :ref='`opt_stage_input_${state.id}`')
                button.btn.btn-default.btn-block.rounded-0.link.text-left.text-danger(type='button' @click='delete_stage(state)') 삭제
          //- div.th.bg-white.border-left(style='width: 200px; margin: -1px; 0px;')
        div.th.bg-white.py-0(style='width: 200px;')
          button.btn.btn-sm.py-2(type='button' @click='add_stage()')
            b-icon-plus.mr-1
            | 단계 추가
      //- pre {{rows_by_status_id}}
      table.table.table-project(v-if='cols_ready' style='width: max-content')
        tbody
          tr
            //- td.td.border-left.hover(style='width: 200px; height: 90vh' v-for='state in pipeline.config.status')
            td.border-0.p-1(style='width: 240px;' v-for='state in pipeline.config.status')
              .mt-1.p-2.rounded.bg-lane.py-3(style='min-height: calc(60vh); height: calc(90vh - 30px); overflow: scroll;')
                draggable(:list='rows_by_status_id[state.id]' group='pipeline-card' ghost-class='ghost-hide' animation='200' @change='drag_change($event, state)' style='min-height: calc(90vh - 70px);')
                  //- handle='.handle' animation='200' ghost-class='ghost'
                  //- transition-group(type='transition' :name="'flip-list'")
                  div(v-for='row in rows_by_status_id[state.id]' :key='row.id')
                    //- span {{row}}
                    //- .p-1.shadow-sm.border.rounded.card-project-record.mb-1(
                    .p-2.border.rounded-lg.border-bottom.card-project-record.mb-2(
                      @click.exact='open_record(row)'
                    )
                      //- pre {{row}}
                      span.note.d-block.p-1.pl-2.text-dark.mb-2(style='border-left: solid 2px #f9e79f' v-if='row.last_note && row.last_note.json') {{row.last_note.json.note}}
                      .d-flex.flex-wrap
                        span.m-0(v-if='row.last_note && row.last_note.json && row.last_note.json.date')
                          strong {{row.last_note.json.date.date | date}}
                      small.opacity-50.d-flex.flex-wrap.text
                        span.text.pr-1(v-if='pipeline.config.show_customer_name') {{row.json.__3 && row.json.__3[0]}}
                        span.text.pr-1(v-if='pipeline.config.show_customer_phone') {{row.json.__1 && row.json.__1[0]}}
                        span.text.pr-1(v-if='pipeline.config.show_customer_email') {{row.json.__2 && row.json.__2[0]}}
                        span(v-if='!pipeline.config.show_customer_name && !pipeline.config.show_customer_phone && !pipeline.config.show_customer_email') {{row.field_text}}
                      .text-right
                        small.d-block.opacity-50
                          template(v-if='$store.state.documents_by_id && $store.state.documents_by_id[row.document_id]') {{$store.state.documents_by_id[row.document_id].name}}

    modal(name='record' width='1130px' :height='`auto`' :scrollable='true' draggable='.header-draggable'
      @before-close='record_before_close'
      transition='none'
      overlay-transition='none'
    )
      //- :clickToClose='false'
      button.btn.btn-default.float-right.text-muted.rounded-0.bg-light(type='button' @click='$modal.hide("record")' style='font-size: 1.5rem'): b-icon-x
      //- div(style='height: 650px')
      div
        document-record(
          :property='property'
          :document='document'
          :document_record_id='document_record_id'
          @loaded='record_did_loaded'
          @updated='record_did_updated'
          @updated_note='record_note_did_updated'
          @updated_pipeline='record_did_updated'
          @closing='$modal.hide("record")'
        )
        //- :record_id='record_id'
        //- :tags='tags'
        //- @tag_updated='record_tag_did_updated'

</template>

<script>

import ExcelImport from '@/components/ExcelImport'
import DocumentRecord from '@/components/DocumentRecord'
import ContactSearchFilter from "@/components/ContactSearchFilter";

import { keyBy, sortBy } from 'lodash'
import { customAlphabet } from 'nanoid'
const nanoid = customAlphabet('123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz', 5)

import draggable from 'vuedraggable'
const xlsx = require('xlsx')
const moment = require('moment')

export default {
  name: 'index',
  props: ['pipeline_id', 'pipeline_name', 'document_record_id'],
  components: {
    ExcelImport, DocumentRecord, ContactSearchFilter,
    draggable,
  },
  computed: {
    session() {
      return this.$store.state.session
    },
    property() {
      return this.$store.state.property
    },
  },
  watch: {
    '$store.state.pipelines'() {
      this.load()
    },
    'pipeline_id'() {
      this.rows_ready = false
      this.load()
    },
    'document_record_id'() {
      this.open_modal()
    },
  },
  mounted() {
    this.load()
    // setTimeout(() => {
    //   this.open_filter_modal()
    // }, 300);
  },
  filters: {
    first(e) {
      return e ? e[0] : ''
    },
  },
  data() {
    return {
      done: false,
      loading: false,
      adding: false,
      document: {},
      rows: [],
      rows_count: 0,
      rows_ready: false,
      rows_selected_count: 0,
      fills: Array.from('00000000000000000000000'),

      record_loaded: false,

      dropdown_group_record_active: false,

      filters: [],
      selected_filter: null,

      saved_filter: {},
      form_filter: {
        name: '',
        is_shared: 'N',
      },
      filter_changed: false,

      tags: [],
      tags_by_record_id: {},
      tags_count: null,

      min_height: '90vh',
      should_open_new_window: false,
      selected_all: false,

      sort: 'DESC',
      sortby: 'created_at',
      cols: [],
      cols_by_key: {},
      cols_by_key_loaded: false,

      tag_selected_action: '',

      fullpage_record_id: null,
      scroll_stop: false,

      cols_ready: false,
      cols_primary: [],

      groups: [],
      groups_by_format: {},

      // document_record_id: null,

      drag: false,

      drag_options: {
        animation: 200,
        ghostClass: 'ghost',
      },
      cols_ordered: [],

      new_filter_name: '',
      current_filter: {},

      has_document_id: [],

      checked_projects: [],
      project_filter_input: '',
      project_filter_search_input: {},

      dates: [],
      date_indexed: {},
      from: moment().format('YYYY-MM-DD'),
      to: moment().add('22', 'days').format('YYYY-MM-DD'),
      today: moment().format('YYYY-MM-DD'),
      dates_dow: {},

      status: [],
      status_by_name: {},

      status_drag: false,

      status_drag_options: {
        animation: 200,
        ghostClass: 'ghost',
      },

      card_drag: false,

      // card_drag_options: {
      //   animation: 200,
      //   ghostClass: 'ghost',
      //   // group: 'pipeline-card',
      // },

      pipeline: {},

      rows_by_status_id: {},
    }
  },
  methods: {
    toggle_customer(name) {
      const key = `show_customer_${name}`
      this.pipeline.config[key] = !this.pipeline.config[key]
      this.save_pipeline_name()
    },
    async save_pipeline_name() {
      try {
        this.loading = true
        try {
          const r = await this.$http.put(`/v1/property/${this.property.id}/views/pipelines/${this.pipeline.id}`, {
            name: this.pipeline.name,
            config: this.pipeline.config,
          })
          if (r?.data?.message != 'ok') throw new Error('변경 실패. ' + r.data.message)

          // this.load_pipelines()
          setTimeout(() => {
            this.$store.dispatch('pipelines', this.property.id)
          }, 500);
        } catch (error) {
          this.$modal.show('dialog', {
            title: '알림',
            text: error.message,
          })
        }
        setTimeout(() => { this.loading = false }, 300)

      } catch (error) {
        alert(error)
      }
    },
    async delete_pipeline() {
      try {
        if (!confirm('파이프라인을 삭제하시겠습니까?')) return false
        this.loading = true
        try {
          const r = await this.$http.delete(`/v1/property/${this.property.id}/views/pipelines/${this.pipeline.id}`)
          if (r?.data?.message != 'ok') throw new Error('삭제 실패. ' + r.data.message)

          // this.load_pipelines()
          setTimeout(() => {
            this.$store.dispatch('pipelines', this.property.id)
          }, 500);
          this.$router.push({name: 'layout'})
        } catch (error) {
          this.$modal.show('dialog', {
            title: '알림',
            text: error.message,
          })
        }
        setTimeout(() => { this.loading = false }, 300)

      } catch (error) {
        alert(error)
      }
    },
    rename_stage(state) {
      const name = prompt('Name?', state.name)
      if (!name) return
      this.pipeline.config.status = this.pipeline.config.status.map(e => {
        if (e.id == state.id) {
          e.name = name
        }
        return e
      })
      this.save_pipeline_status()
    },
    delete_stage(state) {
      const rows = this.rows_by_status_id[state.id]
      if (rows && rows.length) {
        this.$modal.show('dialog', {
          title: '알림',
          text: `삭제 실퍠: ${rows.length}개의 항목 존재`,
        })
        return
      }
      this.pipeline.config.status = this.pipeline.config.status.filter(e => {
        return (e.id != state.id)
      })
      this.save_pipeline_status()
    },
    async drag_change(e, new_state) {
      try {
        console.log(e, new_state)
        if (e.removed) return
        if (e.moved) {
          const a = this.rows_by_status_id[new_state.id][e.moved.oldIndex]
          const b = this.rows_by_status_id[new_state.id][e.moved.newIndex]
          try {
            await this.$http.put(`/v1/property/${a.property_id}/views/pipelines/${a.pipeline_id}/records/${a.id}/order`, {
              order: e.moved.oldIndex,
              })
            await this.$http.put(`/v1/property/${b.property_id}/views/pipelines/${b.pipeline_id}/records/${b.id}/order`, {
              order: e.moved.newIndex,
            })
            for (let i=0; i<this.rows_by_status_id[new_state.id].length; i++) {
              if (i === e.moved.oldIndex) continue
              if (i === e.moved.newIndex) continue
              const o = this.rows_by_status_id[new_state.id][i]
              if (o.order === null) {
                await this.$http.put(`/v1/property/${o.property_id}/views/pipelines/${o.pipeline_id}/records/${o.id}/order`, {
                  order: i,
                })
              }
            }
            // if (r?.data?.message != 'ok') throw new Error('변경 실패. ' + r.data.message)

            // this.load_pipelines()
            // setTimeout(() => {
            //   this.$store.dispatch('pipelines', this.property.id)
            // }, 500);
          } catch (error) {
            this.$modal.show('dialog', {
              title: '알림',
              text: error.message,
            })
          }
          setTimeout(() => { this.loading = false }, 300)
          return
        }

        const el = e.added.element
        this.loading = true
        // const last_order = this.rows_by_status_id[new_state.id].length
        try {
          const r = await this.$http.put(`/v1/property/${el.property_id}/views/documents/${el.document_id}/records/row/${el.record_id}/pipelines/${el.pipeline_id}/records/${el.id}`, {
            state: new_state,
            // order: last_order,
            order: e.added.newIndex,
          })
          if (r?.data?.message != 'ok') throw new Error('변경 실패. ' + r.data.message)
          for (let i=0; i<this.rows_by_status_id[new_state.id].length; i++) {
            if (i === e.added.newIndex) continue
            const o = this.rows_by_status_id[new_state.id][i]
            if (o.order >= e.added.newIndex) {
              await this.$http.put(`/v1/property/${o.property_id}/views/pipelines/${o.pipeline_id}/records/${o.id}/order`, {
                order: i,
              })
            }
          }

          // this.load_pipelines()
          setTimeout(() => {
            this.$store.dispatch('pipelines', this.property.id)
          }, 500);
        } catch (error) {
          this.$modal.show('dialog', {
            title: '알림',
            text: error.message,
          })
        }
        setTimeout(() => { this.loading = false }, 300)

      } catch (error) {
        alert(error)
      }
    },

    count_records_by_status(id) {
      let i = 0
      for (const e of this.rows) {
        if (e.state.id == id) i++
      }
      return i
    },
    async status_drag_end() {
      try {
        this.status_drag = false
        this.save_pipeline_status()
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async add_stage() {
      // const name = prompt('Name?')
      // if (!name) return
      const id = nanoid(6)
      this.pipeline.config.status.push({
        name: '이름',
        id,
      })
      this.save_pipeline_status()
      setTimeout(() => {
        this.$root.$emit("bv::show::popover", `opt_stage_${id}`)
        setTimeout(() => {
          this.$refs[`opt_stage_input_${id}`][0].focus()
        }, 100);
        this.trigger_index()
      }, 300);
    },
    async save_pipeline_status() {
      this.loading = true
      try {
        const pipeline = Object.assign({}, this.pipeline, {
          config: Object.assign({}, this.pipeline.config, {
            status: this.pipeline.config.status,
          })
        })
        const r = await this.$http.put(`/v1/property/${this.property.id}/views/pipelines/${this.pipeline.id}`, pipeline)
        if (r?.data?.message != 'ok') throw new Error('목록 항목 저장 실패')

        setTimeout(() => {
          this.trigger_index()
        }, 300);
        // await this.$store.dispatch('pipelines', this.property.id)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      setTimeout(() => { this.loading = false }, 300)
    },
    async trigger_index() {
      try {
        await this.$http.post(`/v1/property/${this.property.id}/views/pipelines/${this.pipeline.id}/actions/reindex`)
        setTimeout(() => {
          this.$store.dispatch('pipelines', this.property.id)
        }, 500);
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },

    async open_modal() {
      if (this.document_record_id) {
        if (!this.document || !this.document.id) {
          try {
            this.loading = true
            const r = await this.$http.get(`/v1/property/${this.property.id}/views/documents/records/row/${this.document_record_id}/find_document_id`)
            const document_id = r?.data?.row?.document_id
            if (!document_id) throw new Error('해당 내역의 프로젝트를 찾을 수 없습니다.')
            this.document = this.$store.state.documents_by_id[document_id]
          } catch (error) {
            this.$modal.show('dialog', {
              title: '알림',
              text: error.message,
            })
          }
        }
        this.$modal.show('record')
      } else {
        this.$modal.hide('record')
      }
    },
    record_did_loaded() {
      this.record_loaded = true
      // setTimeout(() => { this.loading = false }, 300)
    },
    record_did_updated() {
      console.log('reload from record_did_updated')
      this.load()
    },
    record_note_did_updated() {
      console.log('reload from record_note_did_updated')
      this.load()
    },
    record_before_close(event) {
      // event.cancel()
      if (this.document_record_id) {
        // this.document_record_id = null
        // this.open_modal()
        this.$router.push({
          name: 'pipeline.view',
        })
        setTimeout(() => {
          this.update_title()
        }, 100);
      }
    },
    open_record_new(row) {
      const r = this.$router.resolve({
        name: 'pipeline.view.record',
        params: {
          document_record_id: row.record_id,
        }
      })
      window.open(r.href, '_blank')
      return false
    },
    open_record(row) {
      this.document = this.$store.state.documents_by_id[row.document_id]
      this.$router.push({
        name: 'pipeline.view.record',
        params: {
          document_record_id: row.record_id,
        }
      })
    },
    select_all() {

    },
    update_title() {
      setTimeout(() => {
        const text = []
        text.push(this.pipeline.name)
        if (!this.document_record_id) {
          document.title = text.join(' ')
        }
      }, 100);
    },
    async load() {
      if (!this.$store.state.pipelines || this.$store.state.pipelines.length == 0) return
      if (!this.$store.state.property) return

      this.pipeline = this.$store.state.pipelines_by_id[this.pipeline_id]

      try {

        // console.log('groups init', this.groups)
        // if (this.groups.length === 0) {
        //   this.groups = [
        //     // {
        //     //   format: '@고객아이디',
        //     // },
        //     {
        //       format: '@고객이름',
        //     },
        //     // {
        //     //   format: '@고객연락처',
        //     // },
        //     // {
        //     //   format: '@고객이메일',
        //     // },
        //   ]
        // }
        // this.groups_by_format = Object.assign({}, ...this.groups.map(e => {
        //   return { [e.format]: e }
        // }))

        this.loading = true
        const r = await this.$http.get(`/v1/property/${this.property.id}/views/pipelines/${this.pipeline.id}/records`, {
          params: {
            // groups: JSON.stringify(this.groups),
            // filters: JSON.stringify(this.filters),
            // sort: this.sort,
            // sortby: this.sortby,
          }
        })
        const m = r?.data?.message || ''
        if (m != 'ok') {
          throw new Error('불러오기 실패: '+ r?.data?.message)
        }
        if (this.pipeline.config.status.length == 0) {
          this.pipeline.config.status = [
            {
              name: '연락대기',
              id: nanoid(6),
            },
            {
              name: '연락중',
              id: nanoid(6),
            },
            {
              name: '완료',
              id: nanoid(6),
            },
            {
              name: '이탈',
              id: nanoid(6),
            },
          ]
          setTimeout(() => {
            this.save_pipeline_status()
            this.trigger_index()
          }, 300);
        }
        this.status = this.pipeline.config.status || []
        this.status_by_name = keyBy(this.status, 'name')
        // this.record_indexed_by_id = r.data.record_indexed_by_id
        // this.record_log_indexed_by_id = r.data.record_log_indexed_by_id
        const rows_by_status_id = {}
        for (const s of this.status) {
          rows_by_status_id[s.id] = sortBy(r.data.rows.filter(e => {
            return e.state.id == s.id
          }).map(e => {
            // e.order = e.order || e.id
            return e
          }), 'order')
        }
        this.rows_by_status_id = rows_by_status_id
        this.rows_count = r.data.rows_count
        // this.rows_selected_count = 0
        // this.rows = r.data.rows
        this.rows_ready = true

        this.cols_ready = true
        const cols_by_key = {}

        this.saving_text = '저장'
        this.done = true

        // this.document_record_id = this.document_record_id
        this.open_modal()

        // this.update_count_stat()

        // this.open_filter_modal()
        this.update_title()

        if (!this.pipeline.config.stat) {
          setTimeout(() => {
            this.trigger_index()
          }, 300);
        }
      } catch (error) {
        console.log(error)
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      this.loading = false
    },
  },
}
</script>
